<script setup>
import { marked } from 'marked';
import vPrism from 'o365.vue.directive.prism.js';
import { loadCdnStyle } from 'o365.modules.helpers.js';
import { ref, watch } from 'vue';
import Prism from 'prismjs';
import vSanitizehtml from 'o365.vue.directive.sanitizehtml.ts';
import DOMPurify from 'dom-purify';
loadCdnStyle('marked-css');

const props = defineProps({
    value: null,
    markedOptions: {
        type: Object,
        default: () => ({}),
    },
    debounce: {
        type: [String, Number]
    },
    noSanitize: {
        type: Boolean,
        default: false
    },
    themeOverride: null,
});

function escapeHTML(html) {
    var escape = document.createElement('textarea');
    escape.textContent = html;
    return escape.innerHTML;
}

const supportedLanguages = new Set(Object.keys(Prism.languages));
supportedLanguages.add('mermaid');

const codeRenderer = function (options) {
    let code = options.text;
    let language = options.lang;
    if (!supportedLanguages.has(language)) {
        console.warn(`Language not supported by prism.js: ${language}\nYou can find if a language can be supported here: https://prismjs.com\nCreate a WF to get languages added.\n\nCurrently supported languages: [${Object.keys(Prism.languages).join(', ')}]`);
        code = language + '\n' + code;
        language = ''
    }

    if (language == 'mermaid') {
        return '<div class="mermaid">' + code + '</div>';
    } else {
        if (!props.noSanitize) {
            return '<pre style="padding-top:30px;"><code class="language-' + language + '">' + code + '</code></pre>';
        }

        return '<pre style="padding-top:30px;"><code class="language-' + language + '">' + escapeHTML(code) + '</code></pre>';
    }

};
const renderer = new marked.Renderer();
renderer.code = codeRenderer;

const el = ref(null);


marked.setOptions({
    renderer: renderer,
    gfm: true,
    async: true,
    ...props.markedOptions
});

const format = async (pValue) => {
    //const sanitizedValue = DOMPurify.sanitize(pValue ?? '');
    if (!pValue) { return null; }
    let sanitizedValue;
    if (!props.noSanitize) {
        sanitizedValue = escapeHTML(pValue ?? '');
    } else {
        sanitizedValue = pValue;
    }
    return DOMPurify.sanitize(await marked.parse(sanitizedValue))
}

// Optional debounce implementation when value is rapidly changing (code snippets editor for exampe)
const asyncOutput = ref('');
let parseDebounce = null;
watch(() => props.value, (newValue) => {
    if (!props.debounce) {
        format(newValue)?.then((result) => {
            asyncOutput.value = result;
        });
        return;
    }

    if (parseDebounce) { window.clearTimeout(parseDebounce); }
    parseDebounce = window.setTimeout(async () => {
        asyncOutput.value = await format(newValue);
    }, +props.debounce);
}, {
    immediate: true
});

defineExpose({ renderer, marked });

</script>

<template>
    <div ref="el" v-html="asyncOutput" v-prism="{ themeOverride: themeOverride }"></div>
</template>

<style scoped>
::v-deep table {
  border-collapse: collapse;
}
::v-deep th, ::v-deep td {
  border: 1px solid #000;
  padding: 5px;
}
</style>